import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import FormContact from '../components/contactForm/FormContact'
import "../styles/contact.scss"

class ContactPage extends React.Component {

  render() { 
    return (
      <LayoutMain>
        <Helmet>
          <title>Contact</title>
          <meta
            name="contact"
            content="Contact me"
          />
          <bodyAttributes
            className="contact"
          />
        </Helmet>

        <div className="container-contact">

          <div className="contactCopy">
            <h1>Contact</h1>
            <p>I would love to hear from you.  Fill out the form.  Get in touch.</p>
          </div>

          <div className="contactForm">
            <FormContact/>
          </div>



        </div>
      </LayoutMain>
    )
  }
}

export default ContactPage
